import React from 'react'
import waOutline from "@/assets/wa-outline.svg"
import Image from 'next/image'
import Tooltip from '../Tooltip/Tooltip'
import { useTranslation } from 'react-i18next';

export default function FloatingButton({ textWa, home = false }) {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.open(`https://api.whatsapp.com/send?phone=6281287889023&text=%20%20${home == true ? `${t('floating_message_home')}` : `${t("floating_message_hi")}%20${textWa}%20${t("floating_message_at")}`}%20`, "_blank")
  }

  return (
    // <div className="flex relative min-h-screen items-center justify-center">
    <div className="fixed z-20 bottom-10 right-10">
      <Tooltip message={t("floating_call_whatsapp")}>
        <div onClick={handleRedirect} className=" hover:bg-semantic-positive-600 hover:cursor-pointer bg-semantic-positive-500 flex justify-center items-center p-4 rounded-full shadow transition hover:bg-gray-100">
          <Image src={waOutline} />
        </div>
      </Tooltip>
    </div>
    // </div>
  )
}
