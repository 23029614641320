'use client'
import React, { Fragment, useEffect, useState } from 'react'
import logoLO from "@/assets/navbar/logo.png"
import Image from 'next/image'
import navbarList from "@/lang/id/navbar.json"
import { list_navbar } from '@/helpers/content/id/navbar'
import Link from 'next/link'
import { montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import { useTranslation } from 'react-i18next';
import flag_en from '../../assets/flag_switch_en.svg'
import flag_id from '../../assets/flag_switch_id.svg'
import i18n from '../../helpers/i18n'
import { setCookie, getCookie } from 'cookies-next'; // import getCookie
import { useRouter } from 'next/router';
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'

export default function Navbar({ moveToSection, setnextTab, lang }) {
  const router = useRouter();
  const { locale, pathname, asPath, query } = router;
  const [yOffset, setYOffset] = useState(0);
  const [hover, sethover] = useState(false)
  const [hoverActive, sethoverActive] = useState(null)
  const [langHover, setlangHover] = useState(false)
  const { t, i18n } = useTranslation();
  const [languageFlag, setLanguangeFlag] = useState('id')
  const language = getCookie('NEXT_LOCALE') || 'id';

  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.scrollY)
      setYOffset(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setLanguangeFlag(language)
    i18n.changeLanguage(language);

    router.push({ pathname, query }, asPath, { locale: language });  // Mengganti URL sesuai bahasa
  }, [language])

  const handleHover = (name) => {
    sethover(true)
    sethoverActive(name)
  }

  const handleBlur = () => {
    sethover(false)
    sethoverActive(null)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    router.push({ pathname, query }, asPath, { locale: lng });  // Mengganti URL sesuai bahasa

    // Set cookie untuk menyimpan bahasa, dengan masa berlaku 7 hari
    setCookie('NEXT_LOCALE', lng, { maxAge: 7 * 24 * 60 * 60, path: '/' });

  };

  const handleRedirect = (value, index) => {
    console.log(value, index)
    if (value.description) {
      setnextTab(index)
      moveToSection('services')
    } else {
      moveToSection(value.type)
    }
  }

  return (
    <nav className={`flex ${yOffset > 0 ? "bg-base-white shadow-[0_8px_40px_0px_rgba(55,65,81,0.20)] z-50" : "z-50"} fixed top-0 w-full xl:mx-auto text-base lg:justify-between py-6 px-8 flex-col lg:flex-row lg:items-center`}>
      <div className='max-w-[1280px] lg:container lg:mx-auto flex lg:items-center lg:justify-between xl:justify-between xl:items-center w-full'>
        <div onClick={() => moveToSection("home")} className='hover:cursor-pointer'>
          <Image src={logoLO} className='w-[154px]' />
        </div>
        <ul className={`${montserratSemibold.className} flex text-base space-x-8`}>
          {
            list_navbar.data.map((v) => {
              return v.position == "center" ? v.list.map((value, index) => (
                <li key={index} onMouseEnter={() => handleHover(value.name)} onMouseLeave={() => handleBlur()} className='relative flex'>
                  <div onClick={() => value.link ? window.open(value.link, "_self") : moveToSection(value.type)} className='hover:bg-gray-cool-100 hover:cursor-pointer text-gray-cool-700 rounded-lg px-6 py-3' >{t(value.type)}</div>
                  {
                    value.sub_list.length > 0 && value.name == hoverActive && hover ? (
                      <div className={` ${value.sub_list[0].description ? "w-[342px]" : "w-[144px]"} flex z-50 flex-col absolute left-0 top-12 bg-base-white rounded drop-shadow-lg`}>
                        {
                          value.sub_list.map((vs, is) => (
                            <>
                              {
                                vs.link ? (
                                  <Link locale={lang} href={vs.link} className={`${is == (value.sub_list.length - 1) ? "" : "border-b"} py-3 hover:cursor-pointer hover:bg-gray-cool-100 border-gray-cool-200 text-gray-cool-500 flex flex-col pt-3 px-6`} key={is}>
                                    <p className={`${montserratSemibold.className} text-base-black`}>{t(vs.name)}</p>
                                    <p className={`${montserratNormal.className} text-gray-cool-500 text-sm`}>{t(vs.description)}</p>
                                  </Link>

                                ) : (
                                  <div onClick={() => moveToSection(vs.type)} className={`${is == (value.sub_list.length - 1) ? "" : "border-b"} py-3 hover:cursor-pointer hover:bg-gray-cool-100 border-gray-cool-200 text-gray-cool-500 flex flex-col pt-3 px-6`} key={is}>
                                    <p className={`${montserratSemibold.className} text-base-black`}>{t(vs.name)}</p>
                                    <p className={`${montserratNormal.className} text-gray-cool-500 text-sm`}>{t(vs.description)}</p>
                                  </div>
                                )
                              }
                            </>
                          ))
                        }
                      </div>
                    ) : ""
                  }
                </li>
              )) : ""
            })
          }
        </ul>
        <ul className={`${montserratSemibold.className} flex space-x-8`}>
          {
            list_navbar.data.map((v) => {
              return v.position == "end" ? v.list.map((value, index) => (
                <li key={index} className='relative flex'>
                  {
                    value.type == "signin" ? (
                      <Link locale={lang} className={`${value.type_button ? "border border-gray-cool-300 rounded-lg" : ""} hover:cursor-pointer hover:bg-gray-cool-100 text-gray-cool-700 rounded-lg px-6 py-3`} target='__blank' href={value.link}>{t(value.name)}</Link>
                    ) : (
                      <div onClick={() => moveToSection(value.type)} className={`${value.type_button ? "border border-gray-cool-300 rounded-lg" : ""} hover:bg-gray-cool-100 hover:cursor-pointer text-gray-cool-700 rounded-lg px-6 py-3`} href={value.link}>{t(value.name)}</div>
                    )
                  }
                </li>
              )) : ""
            })
          }
          <div onMouseEnter={() => setlangHover(true)} className="group cursor-pointer flex items-center justify-center relative">
            <div className='px-1 rounded-lg flex-row flex items-center'>
              {
                languageFlag == 'id' ? (
                  <Image src={flag_id} className='w-[24px] h-[24px] rounded-md border border-solid border-gray-cool-300 bg-base-white'></Image>
                ) : (
                  <Image src={flag_en} className='w-[24px] h-[24px] rounded-md border border-solid border-gray-cool-300 bg-base-white'></Image>
                )
              }
              <div className='text-base text-gray-cool-700 px-3 font-semibold'>{languageFlag.toUpperCase()}</div>
              <ChevronDownIcon className='w-5 h-5 text-gray-cool-400'></ChevronDownIcon>
            </div>
            {
              langHover == true && (
                <div
                  onMouseLeave={() => setlangHover(false)}
                  className="absolute top-[50px] mr-20 z-20 text-base shadow-xl font-semibold text-gray-cool-700 bg-[#FFF] rounded-lg bg-gray-900 py-3 transition-all w-[203px]"
                >
                  <div onClick={() => changeLanguage('en')} className='flex px-3 justify-between flex-row cursor-pointer items-center mt-2'>
                    <div className='flex flex-row items-center'>
                      <Image src={flag_en} className='w-[19.385px] h-[16px] rounded-md border border-solid border-gray-cool-300 bg-base-white'></Image>
                      <div className='ml-2 text-sm font-medium'>English</div>
                    </div>
                    <div>
                      {languageFlag == 'en' && <CheckIcon className='w-5 h-5 '></CheckIcon>}
                    </div>
                  </div>
                  <div className="border-[0.5px] border-gray-cool-200 mt-3" />
                  <div onClick={() => changeLanguage('id')} className='flex px-3 justify-between flex-row mt-4 mb-1 cursor-pointer items-center'>
                    <div className='flex flex-row items-center'>
                      <Image src={flag_id} className='w-[19.385px] h-[16px] rounded-md border border-solid border-gray-cool-300 bg-base-white'></Image>
                      <div className='ml-2 text-sm font-medium'>Indonesia</div>
                    </div>
                    {languageFlag == 'id' && <CheckIcon className='w-5 h-5'></CheckIcon>}
                  </div>
                </div>
              )
            }
          </div>
        </ul>
      </div>
    </nav>
  )
}
