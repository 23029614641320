export const list_navbar = {
    "section": "navbar",
    "data": [
        {
            "position" : "center",
            "list" : [
                {
                    "type" : "home",
                    "name": "Home",
                    "link" : "/",
                    "sub_list": [],
                    "type_button" : false
                },
                {
                    "type" : "services",
                    "name": "services",
                    "link" : "#layanan",
                    "sub_list": [
                        {
                            "type" : "bookeeping",
                            "name" : "service_bookeeping",
                            "slug" : "pembukuan",
                            "link" : "/bookkeeping",
                            "description": "navbar_service_bookeeping_desc"
                        },
                        {
                            "type" : "tax",
                            "name" : "tax",
                            "slug" : "perpajakan",
                            "link" : "/tax",
                            "description": "navbar_service_tax_desc"
                        },
                        {
                            "type" : "reconciliation",
                            "name" : "service_recon",
                            "slug" : "rekonsiliasi-otomatis",
                            "link" : "/reconciliation-automation",
                            "description": "navbar_service_automated_recon_desc"
                        },
                        {
                            "type" : "financial",
                            "name" : "finance_operation",
                            "slug" : "operasi-finansial",
                            "link" : "/finance-operation",
                            "description": "navbar_service_finance_operation_desc"
                        },
                        {
                            "type" : "legalitation",
                            "name" : "business_legality",
                            "slug" : "legalitas-usaha",
                            "link" : "/legal",
                            "description": "navbar_service_business_legal_desc"
                        }
                    ],
                    "type_button" : false
                },
                {
                    "type" : "resources",
                    "name": "Resources",
                    "link" : "#resources",
                    "sub_list": [
                        {
                            "type" : "article",
                            "name" : "navbar_article",
                            "slug" : "artikel",
                            "link" : "https://blog.ledgerowl.com/",
                            "description": ""
                        },
                        {
                            "type" : "client",
                            "name" : "navbar_client",
                            "slug" : "klien-kami",
                            "link" : null,
                            "description": ""
                        }
                    ],
                    "type_button" : false
                },
                {
                    "type" : "contact",
                    "name": "Kontak",
                    "link" : null,
                    "sub_list": [],
                    "type_button" : false
                }
            ]
        },
        {
            "position" : "end",
            "list" : [
                {
                    "type" : "consultation",
                    "name": "pricing_button_text",
                    "link" : "#konsultasi-gratis",
                    "sub_list": [],
                    "type_button" : false
                },
                {
                    "type" : "signin",
                    "name": "sign_in",
                    "link" : "https://app.ledgerowl.com/auth/login",
                    "sub_list": [],
                    "type_button" : true
                }
            ]
        }
    ]
}